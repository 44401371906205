import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoginPopup } from "../Account/Login/LoginPopup";
import SEO from "../Common/SEO";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import ComoanyTabs from "./ComoanyTabs";
import CompanyBanner from "./CompanyBanner";
import { SESSION_AUTH } from "../../common/auth";
import { COMPANY_SERVICES, USER_SERVICES } from "../../services";
import "./style.scss";

const Company = () => {
  const { orgSlug } = useParams();
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const dataFetchedRef = useRef(false);
  const [companyData, setCompanyData] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [loginRequest, setLoginRequest] = useState(false);

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };
  useEffect(() => {
    if (isUserSession) {
      setLoginRequest(false);
    }
  }, [isUserSession]);

  useEffect(() => {
    if (dataFetchedRef.current || !orgSlug) return;
    dataFetchedRef.current = true;
    setLoading(true);
    COMPANY_SERVICES.profile({
      slug: orgSlug,
      userId: isUserSession.userId,
      org: isUserSession?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setCompanyData(data.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
          return navigate("/");
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err.message });
      })
      .finally(() => {
        dataFetchedRef.current = false;
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSlug, isUserSession]);

  const handleWatching = async type => {
    setLoading(true);
    try {
      const data = await USER_SERVICES.watchlistAction({
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
        type: type,
        id: companyData?.id,
      });
      if (data.code === 200) {
        setCompanyData(prevData => ({
          ...prevData,
          isWatching: type == "add" ? 1 : 0,
        }));
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {loading && <FullPageProgress fixed />}
      {companyData ? (
        <React.Fragment>
          <SEO
            title={`${companyData?.orgName} | hosted events and featured speakers on 6ix Events`}
          />
          <div className="events6ix-layout-page-middle">
            <CompanyBanner
              handleLogin={handleLogin}
              handleWatching={handleWatching}
              {...companyData}
            />
            <ComoanyTabs handleLogin={handleLogin} {...companyData} />
          </div>
          {loginRequest ? (
            <LoginPopup
              dropOut={true}
              cancel={true}
              handleLogin={handleLogin}
            />
          ) : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default Company;
