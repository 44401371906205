import React from "react";
import EventFooterMenuCMS from "./EventFooterMenuCMS";

const EventFooterBottom = () => {
  return (
    <div className="events6ix-layout-footer-bottom">
      <EventFooterMenuCMS />
      <div className="events6ix-layout-footer-bottom-text">
        JOIN OR CREATE INVESTOR COMMUNITIES
      </div>
    </div>
  );
};

export default EventFooterBottom;
