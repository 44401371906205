import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import FullPageProgress from "../../components/FullPageProgress";
import { Cross, LogoIcon, SearchIcon } from "../../icons";
import { LoginPopup } from "../Account/Login/LoginPopup";
import ConfirmDialog from "../Common/ConfirmDialog";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import {
  ACCOUNT_SERVICES,
  COMMUNITY_SERVICES,
  USER_SERVICES,
} from "../../services";

const LaningCommunity = () => {
  const { isUserSession, LOGOUT_USER_SESSION, UPDATE_USER_SESSION } =
    SESSION_AUTH();
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const dataFetchedRef = useRef(false);
  const [loginRequest, setLoginRequest] = useState(false);
  const [confirmCreateOwn, setConfirmCreateOwn] = useState(false);

  useEffect(() => {
    if (textSearch) {
      setLoading(true);
      COMMUNITY_SERVICES.search({ search: textSearch })
        .then(data => {
          if (data.code === 200) {
            setSearchData(textSearch ? data.data?.list ?? [] : []);
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSearchData([]);
    }
  }, [textSearch]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    COMMUNITY_SERVICES.list()
      .then(data => {
        if (data.code === 200) {
          if (data.data?.list) {
            setListData(data.data?.list);
          }
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const onCreateOwn = () => {
    if (isUserSession) {
      setConfirmCreateOwn(true);
    } else {
      handleLogin();
    }
  };
  useEffect(() => {
    if (isUserSession) {
      setLoginRequest(false);
    }
  }, [isUserSession]);

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  const onAcceptCreateOwn = () => {
    setConfirmCreateOwn(false);
    createUserOrgAccount();
  };
  const createUserOrgAccount = () => {
    try {
      setLoader(true);
      setConfirmCreateOwn(false);
      ACCOUNT_SERVICES.create({
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            switchUserAccount(data.data);
            return;
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
          setLoader(false);
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
          setLoader(false);
        });
    } catch (err) {
      ToastManager.addToast({ title: err.message });
    }
  };

  const switchUserAccount = item => {
    if (item?.accountId === isUserSession?.org?.id) return;
    setLoader(true);
    USER_SERVICES.switchAccount({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      switch: item?.accountId,
    })
      .then(data => {
        if (data.code === 200) {
          UPDATE_USER_SESSION({ org: data?.data });
          navigate("/R_C_P", { state: { replace: true } });
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      {loader ? <FullPageProgress fixed={true} /> : null}
      <section className="events-page-landing-section events-page-landing-community">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header text-center">
            <h2 className="events-page-landing-section-header-heading">
              Join an investor community
            </h2>
            <div className="events-page-landing-section-header-text fw-500 text-white">
              or{" "}
              <span className="text-primary text-link" onClick={onCreateOwn}>
                create your own
              </span>
            </div>

            <div className="event-page-explore-filter-search events-page-landing-community-search">
              <span className="event-page-explore-filter-search-icon">
                <SearchIcon />
              </span>
              <input
                className="event-page-explore-filter-search-input"
                type="search"
                value={textSearch}
                onChange={e => {
                  setTextSearch(e.target.value);
                }}
                placeholder="Search for any company"
              />
              {textSearch && (
                <>
                  <button
                    onClick={() => {
                      setTextSearch("");
                    }}
                    className="event-page-explore-filter-search-btn"
                    type="button"
                  >
                    <Cross />
                  </button>

                  <div className="event-page-explore-filter-search-result">
                    {searchData?.length > 0 ? (
                      searchData.map((item, index) => (
                        <Link
                          key={index}
                          to={`/company/${item?.orgSlug}`}
                          className="event-page-explore-filter-search-result-item"
                        >
                          {item?.orgName}
                        </Link>
                      ))
                    ) : (
                      <div className="event-page-explore-filter-search-result-noItem">
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <>No search result found!</>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="events-page-landing-section-content">
            {listData?.length > 0 ? (
              <div className="events-page-landing-community-row">
                {listData.map((item, index) => (
                  <Link
                    to={`/company/${item?.orgSlug}`}
                    className="events-page-landing-community-row-item"
                    key={index}
                  >
                    <div className="events-page-landing-community-row-item-inner">
                      <div
                        className="events-page-landing-community-row-item-logo"
                        style={{
                          backgroundColor: item?.orgLogo
                            ? item?.orgLogoColor
                            : "",
                        }}
                      >
                        {item?.orgLogo ? (
                          <img src={item.orgLogo} alt="logo" />
                        ) : (
                          <LogoIcon />
                        )}
                      </div>
                      <div className="events-page-landing-community-row-item-name">
                        {item?.orgName}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : loading ? (
              <FullPageProgress />
            ) : null}
          </div>
        </div>
      </section>

      {loginRequest ? (
        <LoginPopup dropOut={true} cancel={true} handleLogin={handleLogin} />
      ) : null}
      {confirmCreateOwn && (
        <ConfirmDialog
          open={confirmCreateOwn ? true : false}
          data={{
            title: "Create Your Own Community",
            text: `Are you sure you want to create your own investor community? You'll become the community owner and can invite others to join.`,
            yesLabel: "Confirm",
          }}
          onCancel={() => {
            setConfirmCreateOwn(false);
          }}
          onAccept={onAcceptCreateOwn}
        />
      )}
    </React.Fragment>
  );
};

export default LaningCommunity;
