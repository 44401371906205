import React from "react";
import { Flex } from "@100mslive/roomkit-react";
import EventFooterAbout6ix from "./EventFooterAbout6ix";
import EventFooterContact from "./EventFooterContact";

const EventFooterContentLeft = () => {
  return (
    <Flex className="events6ix-layout-footer-content-left">
      <Flex className="events6ix-layout-footer-content-left-row">
        <EventFooterAbout6ix />
        <EventFooterContact />
      </Flex>
    </Flex>
  );
};

export default EventFooterContentLeft;
